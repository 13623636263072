import React from 'react';

export function LogoCW() {
  return (
    <svg width="230" height="33" viewBox="0 0 230 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6511 3.83076e-06C37.7845 0.00671903 41.5331 2.39884 43.2412 6.11969C44.9492 9.84051 44.302 14.2046 41.5853 17.2842L28.4607 30.2735C26.7922 31.9717 24.4988 32.9291 22.104 32.927C19.6978 32.9265 17.3933 31.9675 15.7109 30.2669L14.8002 29.3766L19.2492 24.9785L20.1963 25.9083C20.6853 26.4268 21.3687 26.7233 22.0858 26.7282C22.8064 26.7359 23.4992 26.4535 24.005 25.946L36.5923 13.5027C37.2851 12.7404 37.6638 11.7493 37.654 10.7247C37.6641 9.60581 37.2219 8.52923 36.4254 7.73417C35.6289 6.93912 34.5442 6.49143 33.4125 6.49059C32.3713 6.47793 31.3641 6.85458 30.5923 7.54504L11.6534 26.269L2.58946 17.2711C0.917073 15.3627 -0.00240406 12.9217 4.72078e-06 10.3968C0.0159248 6.25366 2.51028 2.5125 6.35548 0.864452C10.2007 -0.783593 14.668 -0.0261631 17.738 2.79434L19.0269 4.06687L14.5563 8.48632C14.5563 8.48632 13.843 7.75658 13.5909 7.53685C12.8218 6.85348 11.8215 6.48016 10.7874 6.49059C9.65701 6.48406 8.57061 6.9235 7.76909 7.71155C6.96757 8.49961 6.51711 9.57116 6.51759 10.6887C6.50412 11.7211 6.88363 12.7208 7.58089 13.4896L11.6218 17.4843L25.7385 3.5454C27.7348 1.29001 30.6197 -0.00256807 33.6511 3.83076e-06Z"
        fill="#126DFE"
      />
      <path d="M54.9118 0H53.7198V32.927H54.9118V0Z" fill="#B8BCC2" />
      <path
        d="M217.898 23.6219L214.958 13.2195H217.458L219.286 20.5337H219.381L221.25 13.2195H223.722L225.591 20.493H225.693L227.494 13.2195H230L227.054 23.6219H224.501L222.55 16.5921H222.408L220.458 23.6219H217.898Z"
        fill="#031026"
      />
      <path
        d="M208.983 23.825C207.968 23.825 207.087 23.6015 206.342 23.1546C205.597 22.7076 205.019 22.0823 204.608 21.2786C204.202 20.475 203.999 19.5359 203.999 18.4613C203.999 17.3868 204.202 16.4454 204.608 15.6372C205.019 14.829 205.597 14.2015 206.342 13.7545C207.087 13.3075 207.968 13.084 208.983 13.084C209.999 13.084 210.88 13.3075 211.625 13.7545C212.37 14.2015 212.945 14.829 213.352 15.6372C213.763 16.4454 213.968 17.3868 213.968 18.4613C213.968 19.5359 213.763 20.475 213.352 21.2786C212.945 22.0823 212.37 22.7076 211.625 23.1546C210.88 23.6015 209.999 23.825 208.983 23.825ZM208.997 21.861C209.548 21.861 210.009 21.7098 210.379 21.4073C210.749 21.1003 211.024 20.6894 211.205 20.1747C211.39 19.66 211.483 19.0866 211.483 18.4545C211.483 17.8179 211.39 17.2423 211.205 16.7276C211.024 16.2084 210.749 15.7953 210.379 15.4882C210.009 15.1812 209.548 15.0277 208.997 15.0277C208.433 15.0277 207.963 15.1812 207.588 15.4882C207.218 15.7953 206.94 16.2084 206.755 16.7276C206.575 17.2423 206.485 17.8179 206.485 18.4545C206.485 19.0866 206.575 19.66 206.755 20.1747C206.94 20.6894 207.218 21.1003 207.588 21.4073C207.963 21.7098 208.433 21.861 208.997 21.861Z"
        fill="#031026"
      />
      <path d="M201.925 9.75204V23.6219H199.473V9.75204H201.925Z" fill="#031026" />
      <path
        d="M197.553 13.2195V15.1158H191.404V13.2195H197.553ZM192.941 23.6219V12.2375C192.941 11.5377 193.086 10.9552 193.375 10.4902C193.668 10.0252 194.061 9.67754 194.553 9.44726C195.045 9.21701 195.592 9.10186 196.192 9.10186C196.616 9.10186 196.993 9.13574 197.323 9.20348C197.653 9.27118 197.897 9.33215 198.054 9.38632L197.567 11.2826C197.463 11.251 197.332 11.2194 197.174 11.1878C197.016 11.1516 196.84 11.1336 196.646 11.1336C196.19 11.1336 195.867 11.2442 195.677 11.4655C195.492 11.6821 195.4 11.9937 195.4 12.4V23.6219H192.941Z"
        fill="#031026"
      />
      <path
        d="M183.662 20.3576L183.655 17.3981H184.047L187.786 13.2195H190.651L186.052 18.3394H185.544L183.662 20.3576ZM181.427 23.6219V9.75204H183.878V23.6219H181.427ZM187.955 23.6219L184.569 18.888L186.222 17.161L190.888 23.6219H187.955Z"
        fill="#031026"
      />
      <path
        d="M173.865 23.6219V13.2195H176.242V14.9532H176.351C176.54 14.3527 176.865 13.8899 177.326 13.5649C177.791 13.2353 178.322 13.0705 178.918 13.0705C179.053 13.0705 179.204 13.0773 179.371 13.0908C179.543 13.0998 179.685 13.1156 179.798 13.1382V15.3934C179.694 15.3573 179.529 15.3257 179.304 15.2986C179.082 15.267 178.868 15.2512 178.66 15.2512C178.213 15.2512 177.811 15.3483 177.455 15.5424C177.103 15.732 176.825 15.9962 176.622 16.3348C176.418 16.6734 176.317 17.0639 176.317 17.5064V23.6219H173.865Z"
        fill="#031026"
      />
      <path
        d="M166.8 23.825C165.784 23.825 164.904 23.6015 164.159 23.1546C163.414 22.7076 162.836 22.0823 162.425 21.2786C162.019 20.475 161.815 19.5359 161.815 18.4613C161.815 17.3868 162.019 16.4454 162.425 15.6372C162.836 14.829 163.414 14.2015 164.159 13.7545C164.904 13.3075 165.784 13.084 166.8 13.084C167.816 13.084 168.696 13.3075 169.441 13.7545C170.186 14.2015 170.762 14.829 171.168 15.6372C171.579 16.4454 171.784 17.3868 171.784 18.4613C171.784 19.5359 171.579 20.475 171.168 21.2786C170.762 22.0823 170.186 22.7076 169.441 23.1546C168.696 23.6015 167.816 23.825 166.8 23.825ZM166.813 21.861C167.364 21.861 167.825 21.7098 168.195 21.4073C168.565 21.1003 168.841 20.6894 169.021 20.1747C169.207 19.66 169.299 19.0866 169.299 18.4545C169.299 17.8179 169.207 17.2423 169.021 16.7276C168.841 16.2084 168.565 15.7953 168.195 15.4882C167.825 15.1812 167.364 15.0277 166.813 15.0277C166.249 15.0277 165.78 15.1812 165.405 15.4882C165.035 15.7953 164.757 16.2084 164.572 16.7276C164.391 17.2423 164.301 17.8179 164.301 18.4545C164.301 19.0866 164.391 19.66 164.572 20.1747C164.757 20.6894 165.035 21.1003 165.405 21.4073C165.78 21.7098 166.249 21.861 166.813 21.861Z"
        fill="#031026"
      />
      <path
        d="M147.15 23.6219L143.235 9.75204H145.938L148.437 19.9445H148.565L151.234 9.75204H153.692L156.367 19.9512H156.489L158.988 9.75204H161.69L157.776 23.6219H155.297L152.52 13.8899H152.412L149.629 23.6219H147.15Z"
        fill="#031026"
      />
      <path
        d="M137.342 13.2195V15.1158H131.362V13.2195H137.342ZM132.838 10.7272H135.29V20.493C135.29 20.8226 135.339 21.0755 135.439 21.2515C135.543 21.4231 135.678 21.5405 135.845 21.6037C136.012 21.6669 136.197 21.6985 136.4 21.6985C136.554 21.6985 136.694 21.6872 136.82 21.6646C136.951 21.6421 137.05 21.6218 137.118 21.6037L137.531 23.5203C137.401 23.5654 137.213 23.6151 136.969 23.6693C136.73 23.7234 136.437 23.7551 136.089 23.7641C135.475 23.7822 134.922 23.6896 134.43 23.4864C133.937 23.2787 133.547 22.9582 133.258 22.5247C132.974 22.0913 132.834 21.5495 132.838 20.8994V10.7272Z"
        fill="#031026"
      />
      <path
        d="M122.978 17.5267V23.6219H120.526V13.2195H122.869V14.9871H122.991C123.231 14.4047 123.612 13.9419 124.136 13.5988C124.664 13.2556 125.316 13.084 126.093 13.084C126.811 13.084 127.436 13.2375 127.969 13.5446C128.506 13.8516 128.921 14.2963 129.215 14.8787C129.513 15.4612 129.66 16.1677 129.655 16.9985V23.6219H127.204V17.3777C127.204 16.6824 127.023 16.1384 126.662 15.7456C126.305 15.3528 125.811 15.1564 125.179 15.1564C124.75 15.1564 124.368 15.2512 124.034 15.4408C123.705 15.6259 123.445 15.8946 123.255 16.2467C123.07 16.5989 122.978 17.0256 122.978 17.5267Z"
        fill="#031026"
      />
      <path
        d="M113.826 23.825C112.783 23.825 111.883 23.6083 111.124 23.1749C110.37 22.7369 109.79 22.1184 109.384 21.3193C108.977 20.5156 108.774 19.5697 108.774 18.4816C108.774 17.4116 108.977 16.4725 109.384 15.6643C109.795 14.8516 110.368 14.2195 111.104 13.768C111.84 13.312 112.704 13.084 113.698 13.084C114.339 13.084 114.944 13.1879 115.513 13.3956C116.086 13.5988 116.592 13.9148 117.03 14.3437C117.472 14.7726 117.82 15.3189 118.073 15.9826C118.326 16.6418 118.452 17.4274 118.452 18.3394V19.0912H109.925V17.4387H116.102C116.097 16.9691 115.996 16.5515 115.797 16.1858C115.598 15.8156 115.321 15.5244 114.964 15.3122C114.612 15.0999 114.201 14.9939 113.732 14.9939C113.23 14.9939 112.79 15.1158 112.411 15.3596C112.032 15.5989 111.736 15.9149 111.524 16.3077C111.316 16.696 111.21 17.1226 111.206 17.5877V19.0302C111.206 19.6352 111.316 20.1544 111.537 20.5878C111.759 21.0168 112.068 21.3464 112.465 21.5766C112.862 21.8024 113.328 21.9152 113.86 21.9152C114.217 21.9152 114.54 21.8656 114.829 21.7663C115.118 21.6624 115.368 21.5112 115.58 21.3125C115.792 21.1138 115.953 20.8678 116.061 20.5743L118.35 20.8317C118.206 21.4367 117.931 21.9649 117.524 22.4164C117.122 22.8634 116.607 23.211 115.98 23.4593C115.352 23.7031 114.635 23.825 113.826 23.825Z"
        fill="#031026"
      />
      <path
        d="M107.283 13.2195V15.1158H101.302V13.2195H107.283ZM102.779 10.7272H105.23V20.493C105.23 20.8226 105.28 21.0755 105.379 21.2515C105.483 21.4231 105.619 21.5405 105.786 21.6037C105.953 21.6669 106.138 21.6985 106.341 21.6985C106.495 21.6985 106.635 21.6872 106.761 21.6646C106.892 21.6421 106.991 21.6218 107.059 21.6037L107.472 23.5203C107.341 23.5654 107.154 23.6151 106.91 23.6693C106.671 23.7234 106.377 23.7551 106.03 23.7641C105.416 23.7822 104.862 23.6896 104.37 23.4864C103.878 23.2787 103.488 22.9582 103.199 22.5247C102.914 22.0913 102.774 21.5495 102.779 20.8994V10.7272Z"
        fill="#031026"
      />
      <path
        d="M92.9183 17.5267V23.6219H90.4667V13.2195H92.8101V14.9871H92.932C93.1713 14.4047 93.5527 13.9419 94.0765 13.5988C94.6046 13.2556 95.2571 13.084 96.0337 13.084C96.7515 13.084 97.3767 13.2375 97.9095 13.5446C98.4468 13.8516 98.8622 14.2963 99.1557 14.8787C99.4537 15.4612 99.6003 16.1677 99.5958 16.9985V23.6219H97.1443V17.3777C97.1443 16.6824 96.9637 16.1384 96.6025 15.7456C96.2459 15.3528 95.7515 15.1564 95.1195 15.1564C94.6904 15.1564 94.309 15.2512 93.9749 15.4408C93.6453 15.6259 93.3855 15.8946 93.196 16.2467C93.0109 16.5989 92.9183 17.0256 92.9183 17.5267Z"
        fill="#031026"
      />
      <path
        d="M83.4015 23.825C82.3857 23.825 81.5051 23.6015 80.7602 23.1546C80.0152 22.7076 79.4374 22.0823 79.0265 21.2786C78.62 20.475 78.4168 19.5359 78.4168 18.4613C78.4168 17.3868 78.62 16.4454 79.0265 15.6372C79.4374 14.829 80.0152 14.2015 80.7602 13.7545C81.5051 13.3075 82.3857 13.084 83.4015 13.084C84.4173 13.084 85.2976 13.3075 86.0425 13.7545C86.7875 14.2015 87.3632 14.829 87.7697 15.6372C88.1806 16.4454 88.3859 17.3868 88.3859 18.4613C88.3859 19.5359 88.1806 20.475 87.7697 21.2786C87.3632 22.0823 86.7875 22.7076 86.0425 23.1546C85.2976 23.6015 84.4173 23.825 83.4015 23.825ZM83.4149 21.861C83.9659 21.861 84.4263 21.7098 84.7967 21.4073C85.1668 21.1003 85.4421 20.6894 85.6227 20.1747C85.8077 19.66 85.9004 19.0866 85.9004 18.4545C85.9004 17.8179 85.8077 17.2423 85.6227 16.7276C85.4421 16.2084 85.1668 15.7953 84.7967 15.4882C84.4263 15.1812 83.9659 15.0277 83.4149 15.0277C82.8505 15.0277 82.3812 15.1812 82.0063 15.4882C81.6362 15.7953 81.3585 16.2084 81.1732 16.7276C80.9926 17.2423 80.9023 17.8179 80.9023 18.4545C80.9023 19.0866 80.9926 19.66 81.1732 20.1747C81.3585 20.6894 81.6362 21.1003 82.0063 21.4073C82.3812 21.7098 82.8505 21.861 83.4149 21.861Z"
        fill="#031026"
      />
      <path
        d="M76.6712 14.4317H74.1383C74.0662 14.0164 73.933 13.6484 73.739 13.3279C73.5447 13.0028 73.3031 12.7274 73.0143 12.5016C72.7253 12.2759 72.3957 12.1066 72.0256 11.9937C71.6597 11.8763 71.2649 11.8176 70.8402 11.8176C70.0863 11.8176 69.4183 12.0072 68.8357 12.3865C68.2531 12.7612 67.7972 13.312 67.4676 14.0389C67.1381 14.7613 66.9733 15.644 66.9733 16.687C66.9733 17.748 67.1381 18.6419 67.4676 19.3688C67.8017 20.0912 68.2579 20.6375 68.8357 21.0077C69.4183 21.3735 70.084 21.5563 70.8337 21.5563C71.2488 21.5563 71.6373 21.5021 71.9985 21.3938C72.3641 21.2809 72.6913 21.1161 72.9804 20.8994C73.2739 20.6827 73.52 20.4163 73.7185 20.1002C73.9217 19.7842 74.0618 19.423 74.1383 19.0167L76.6712 19.0302C76.5765 19.6894 76.3711 20.3079 76.055 20.8858C75.7436 21.4637 75.335 21.9739 74.8294 22.4164C74.3237 22.8543 73.7322 23.1975 73.0549 23.4458C72.3775 23.6896 71.626 23.8115 70.7997 23.8115C69.5807 23.8115 68.4924 23.5293 67.5353 22.965C66.5782 22.4006 65.8243 21.5856 65.2733 20.5201C64.7226 19.4546 64.4473 18.1769 64.4473 16.687C64.4473 15.1925 64.725 13.9148 65.2801 12.8538C65.8356 11.7883 66.5919 10.9733 67.549 10.4089C68.5061 9.84459 69.5896 9.5624 70.7997 9.5624C71.5718 9.5624 72.2896 9.67074 72.9533 9.88747C73.6169 10.1042 74.2084 10.4225 74.7277 10.8424C75.2468 11.2578 75.6736 11.7679 76.0076 12.3729C76.3464 12.9734 76.5675 13.6597 76.6712 14.4317Z"
        fill="#031026"
      />
    </svg>
  );
}
